import React, { Fragment } from 'react'
import { injectIntl, intlShape, FormattedMessage } from 'react-intl'
import Helmet from 'react-helmet'

import Push from '../components/push'
import Title from '../components/title/title'
import Description from '../components/description'
import Button from '../components/forms/button'
import BrandUl from '../components/ul/brand-ul'
import IconsUl from '../components/ul/icons-ul'
import Gallery from '../components/gallery'
import ProjectLinks from '../components/project-links'
import FeedBack from '../components/feedback'
import { Language } from '../i18n/types/languages'
import { projectsRu } from '../i18n/projects/projects-ru'
import { projectsEn } from '../i18n/projects/projects-en'
import scrollTo from 'gatsby-plugin-smoothscroll'

type Props = {
  intl: intlShape
}

// rich text formatting config for FormatttedMessage
const PrimaryText = (props: any) => <span className="text-primary">{props.children}</span>
const valuesConfig = {
  br: <br />,
  primary: (chunks: any) => <PrimaryText displayName="PrimaryText">{chunks}</PrimaryText>
}

const MainContainer = ({ intl }: Props) => {
  const { locale } = intl

  const localeProjects = () => {
    switch (locale) {
      case Language.ru:
        return projectsRu
      default:
        return projectsEn
    }
  }

  return (
    <>
      <Helmet
        title={intl.formatMessage({ id: 'title' })}
        meta={[{ name: 'description', content: intl.formatMessage({ id: 'description' }) }]}
      />

      <Push size={224} adaptiveSize={242} />
      <Title as="h1" super>
        <FormattedMessage id="generalTitle" />
      </Title>
      <Push size={48} />
      <Description super>
        <FormattedMessage id="generalDescription" values={valuesConfig} />
      </Description>

      <Push size={116} adaptiveSize={48} />
      <Button onClick={() => scrollTo('#feedback')} center>
        <FormattedMessage id="discuss" />
      </Button>

      <Push size={332} adaptiveSize={220} />
      <Title id="why-we">
        <FormattedMessage id="detailsTitle" />
      </Title>
      <Push size={48} adaptiveSize={40} />

      <BrandUl>
        <li>
          <FormattedMessage id="detailsItem1" values={valuesConfig} />
        </li>
        <li>
          <FormattedMessage id="detailsItem2" values={valuesConfig} />
        </li>
        <li>
          <FormattedMessage id="detailsItem3" values={valuesConfig} />
        </li>
        <li>
          <FormattedMessage id="detailsItem4" values={valuesConfig} />
        </li>
        <li>
          <FormattedMessage id="detailsItem5" values={valuesConfig} />
        </li>
      </BrandUl>
      <Push size={134} adaptiveSize={38} />
      <IconsUl />

      <Push size={172} adaptiveSize={68} />
      <Title id="projects" as="h2">
        <FormattedMessage id="projects" />
      </Title>

      {localeProjects().map((project, i) => (
        <Fragment key={project.name}>
          <Push size={i === 0 ? 96 : 152} adaptiveSize={i === 0 ? 20 : 96} />
          <Title primary>{project.name}</Title>
          <Push size={16} />
          <Description>{project.description}</Description>
          <Push size={24} adaptiveSize={32} />
          {project.images && project.images.length ? (
            <Gallery images={project.images} adaptiveImages={project.adaptiveImages} />
          ) : null}
          <ProjectLinks
            appStoreLink={project.appStoreLink}
            googlePlayLink={project.googlePlayLink}
            webSiteLink={project.webSiteLink}
          />
        </Fragment>
      ))}

      <Push size={132} adaptiveSize={120} />
      {/* Пока нет еще проектов
      <Button center>
        <FormattedMessage id="moreProjects"/>
      </Button> */}

      <Push size={152} adaptiveSize={112} />
      <FeedBack />
    </>
  )
}

export default injectIntl(MainContainer)
