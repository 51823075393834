import React from 'react'
import styled from 'styled-components'

import { resetUl } from '../../theme/mixins'
import imgTs from '../../images/ts.svg'
import imgReact from '../../images/react.svg'
import imgVue from '../../images/vue.svg'
import imgNode from '../../images/node.svg'
import imgNest from '../../images/nest.svg'
import imgRedux from '../../images/redux.svg'
import imgNext from '../../images/next.svg'
import imgGatsby from '../../images/gatsby.svg'
import imgKubernetes from '../../images/kubernetes.svg'
import imgDocker from '../../images/docker.svg'
import imgGraphQl from '../../images/graphql.svg'
import { breakPoint } from '../../theme/theme'

const IconsUl = () => (
  <StyledUl>
    <ul className="icons-ul-inner">
      <li>
        <img src={imgTs} alt="TypeScript" />
      </li>
      <li>
        <img src={imgReact} alt="React" />
      </li>
      <li>
        <img src={imgVue} alt="Vue" />
      </li>
      <li>
        <img src={imgNode} alt="Node" />
      </li>
      <li>
        <img src={imgNest} alt="Nest" />
      </li>
      <li>
        <img src={imgRedux} alt="Redux" />
      </li>
      <li>
        <img src={imgNext} alt="Next" />
      </li>
      <li>
        <img src={imgGatsby} alt="Gatsby" />
      </li>
      <li>
        <img src={imgKubernetes} alt="Kubernetes" />
      </li>
      <li>
        <img src={imgDocker} alt="Docker" />
      </li>
      <li>
        <img src={imgGraphQl} alt="GraphQl" />
      </li>
    </ul>
  </StyledUl>
)

const StyledUl = styled.div`
  max-width: 820px;
  margin-right: auto;
  margin-left: auto;

  .icons-ul-inner {
    ${resetUl};
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: -68px;
    margin-left: -68px;

    @media ${breakPoint.phone} {
      margin-top: -40px;
      margin-left: -40px;
    }

    li {
      padding-top: 68px;
      padding-left: 68px;

      @media ${breakPoint.phone} {
        padding-top: 40px;
        padding-left: 40px;
      }

      img {
        width: 80px;
        height: 80px;

        @media ${breakPoint.phone} {
          width: 58px;
          height: 58px;
        }
      }
    }
  }
`

export default IconsUl
