import React from 'react'
import styled from 'styled-components'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import { breakPoint, styleVars } from '../../theme/theme'

type Props = {
  adaptiveImages: boolean
  images: string[]
}

const Gallery = ({ images, adaptiveImages }: Props) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: adaptiveImages ? 3 : 1,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: adaptiveImages
      ? [
        {
          breakpoint: +breakPoint.tablet.match(/\d+/),
          settings: {
            slidesToShow: 1
          }
        }
      ]
      : false
  }

  return (
    <StyledSlider>
      <Slider {...settings}>
        {images.map(item => (
          <img key={item} src={item} alt="" />
        ))}
      </Slider>
    </StyledSlider>
  )
}

const StyledSlider = styled.div`
  .slick-slider {
    display: flex;
    justify-content: center;
    margin-bottom: calc(32px * 2 + 16px);

    @media ${breakPoint.landscape} {
      margin-bottom: calc(24px * 2 + 16px);
    }
  }

  .slick-arrow {
    width: 36px;
    height: 32px;
    overflow: hidden;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 16px 36px;
      opacity: 1;
      border-color: transparent;
    }

    @media ${breakPoint.tablet} {
      display: none !important;
    }
  }

  .slick-prev {
    left: 0;

    &:before {
      right: 0;
      border-right-color: ${({ theme }) => theme.colorReverce};
    }
  }

  .slick-next {
    right: 0;

    &:before {
      left: 0;
      border-left-color: ${({ theme }) => theme.colorReverce};
    }
  }

  .slick-list {
    max-width: 936px;
    width: 86vw;

    @media ${breakPoint.tablet} {
      width: auto;
    }
  }

  .slick-slide {
    border-right: 2vw solid transparent;
    border-left: 2vw solid transparent;

    img {
      display: block !important;
      width: auto !important;
      max-width: 100%;
      max-height: 620px;
      margin: 0 auto;
      border: 2px solid ${({ theme }) => (theme.dark ? styleVars.brandGrayLight : 'transparent')};
      border-radius: 24px;
      vertical-align: top;
    }
  }

  .slick-dots {
    bottom: calc((32px + 16px) / -1);

    @media ${breakPoint.landscape} {
      bottom: calc((24px + 16px) / -1);
    }

    li {
      width: auto;
      height: auto;
      margin: 0;
      vertical-align: middle;

      button {
        width: 8px;
        height: 8px;
        margin: 0 8px;
        padding: 0;
        transition: none;

        &::before {
          display: none;
        }

        &,
        &:hover {
          transform: rotate(45deg);
          background-color: ${({ theme }) => theme.colorReverce};
        }
      }

      &.slick-active button {
        width: 16px;
        height: 16px;
        background-color: ${styleVars.brandPrimary};
      }
    }
  }
`

export default Gallery
