import React from 'react'
import styled, { css } from 'styled-components'
import { breakPoint } from '../../theme/theme'

type Props = {
  size: number
  adaptiveSize?: number
}

const Push = (props: Props) => <StyledPush {...props} />

const StyledPush = styled.div`
  margin-top: ${({ size }) => `${size}px`};

  ${({ adaptiveSize }) =>
    adaptiveSize &&
    css`
      @media ${breakPoint.phone} {
        margin-top: ${adaptiveSize}px;
      }
    `};
`

export default Push
