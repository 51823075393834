import React from 'react'

import Layout from '../components/layout/'
import { Language } from '../i18n/types/languages'
import MainContainer from '../containers/main'

type Props = {
  pageContext: {
    locale: Language
  }
}

const IndexPage = ({ pageContext: { locale } }: Props) => {
  return (
    <Layout locale={locale}>
      <MainContainer />
    </Layout>
  )
}

export default IndexPage
