import React, { ReactNode } from 'react'
import styled, { css } from 'styled-components'

import { breakPoint } from '../../theme/theme'

type Props = {
  children: ReactNode
  super?: boolean
}

const Description = ({ children, ...props }: Props) => {
  if (typeof children === 'string') {
    const content = { __html: children }
    return <StyledDescription dangerouslySetInnerHTML={content} {...props} />
  } else {
    return <StyledDescription {...props}>{children}</StyledDescription>
  }
}

const StyledDescription = styled.div`
  max-width: 792px;
  margin-right: auto;
  margin-left: auto;
  text-align: center;

  a {
    color: inherit;
  }

  ${props =>
    props.super
      ? css`
          line-height: 1.3;
          font-size: 24px;

          @media ${breakPoint.phone} {
            font-size: 18px;
          }
        `
      : css`
          line-height: 1.5;
          font-size: 16px;
        `}
`

export default Description
