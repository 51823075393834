import React from 'react'
import styled from 'styled-components'

import { styleVars } from '../../theme/theme'

type Props = {
  children: string
  error?: boolean
}

const Notification = ({ children, ...props }: Props) => <StyledNotification {...props}>{children}</StyledNotification>

const StyledNotification = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 60px;
  padding: 16px;
  font-weight: ${styleVars.fontWeightBold};
  color: #fff;
  background-color: ${({ error }) => (error ? styleVars.brandDanger : styleVars.brandSuccess)};
`

export default Notification
