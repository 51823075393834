import React, { ButtonHTMLAttributes } from 'react'
import styled, { css } from 'styled-components'

import { breakPoint, styleVars } from '../../theme/theme'

type Props = ButtonHTMLAttributes<HTMLButtonElement> & {
  children: string
  center?: boolean
  small?: boolean
}

const Button = ({ children, type = 'button', ...props }: Props) => (
  <StyledButton type={type} {...props}>
    {children}
  </StyledButton>
)

const StyledButton = styled.button`
  width: 100%;
  max-width: 280px;
  height: 50px;
  font-size: 18px;
  font-weight: ${styleVars.fontWeightBold};
  color: ${({ theme }) => (theme.dark ? '#000' : '#fff')};
  background-color: ${styleVars.brandPrimary};
  
  &:hover:not([disabled]) {    
    background-color: ${styleVars.brandPrimaryMedium};
  }
  
  &:active:not([disabled]) {    
    background-color: ${styleVars.brandPrimaryDark};
  }
  
  ${({ disabled }) =>
    disabled &&
    css`
      cursor: default;
      color: ${({ theme }) => (theme.dark ? '#979797' : '#ECECEC')};
      background-color: ${styleVars.brandPrimaryLight};
    `}
  
  ${({ center }) =>
    center &&
    css`
      display: block;
      margin-right: auto;
      margin-left: auto;

      @media ${breakPoint.phone} {
        max-width: none;
      }
    `}
  
  ${({ small }) =>
    small &&
    css`
      width: 168px;
      height: 40px;
      font-size: ${styleVars.fontSizeBase};
      font-weight: ${styleVars.fontWeightBase};
    `}
`

export default Button
