import React, { InputHTMLAttributes, TextareaHTMLAttributes } from 'react'
import styled from 'styled-components'
import { breakPoint, styleVars } from '../../theme/theme'
const element = React.createElement

type Props =
  | InputHTMLAttributes<HTMLInputElement>
  | (TextareaHTMLAttributes<HTMLTextAreaElement> & {
      tag?: string
      danger?: boolean
    })

const FormControl = (props: Props) => <StyledFormControl {...props} />

const StyledFormControl = styled(({ tag, children, danger, ...props }) => element(tag, props, children))`
  width: 100%;
  min-height: 50px;
  padding: 12px;
  border: 2px solid ${({ danger }) => (danger ? styleVars.brandDanger : 'transparent')};
  border-radius: 0;
  font-size: ${styleVars.fontSizeSmall};
  color: ${({ danger }) => (danger ? styleVars.brandDanger : '#000')};
  background-color: #fff;

  textarea& {
    min-height: 104px;

    @media ${breakPoint.phone} {
      min-height: 188px;
    }
  }
`

StyledFormControl.defaultProps = {
  tag: 'input'
}

export default FormControl
