import React, { FormEvent, useEffect, useState } from 'react'
import styled from 'styled-components'
import { injectIntl, FormattedMessage, intlShape } from 'react-intl'

import { breakPoint, styleVars } from '../../theme/theme'
import Title from '../title/title'
import Push from '../push'
import Description from '../description'
import FormControl from '../forms/form-control'
import Button from '../forms/button'
import Notification from '../notification'
import locales from '../../i18n/locales'

type Props = {
  intl: intlShape
}

type FormProps = {
  name: string
  email: string
  phone: string
  message: string
}

const emailRegexp = /^[a-zA-Z0-9_*\-*.*]+@[a-zA-Z0-9\-*]+\.*[A-Za-z.*\-*]*\.[A-Za-z]+$/

const initialValues = {
  name: '',
  email: '',
  phone: '',
  message: ''
}

const FeedBack = ({ intl }: Props) => {
  const { locale } = intl
  const [data, setData] = useState<FormProps>(initialValues)
  const [validationRender, setValidationReady] = useState(false)
  const [errors, setErrors] = useState({})
  const [status, setStatus] = useState<'success' | 'error' | 'submiting' | null>(null)

  const disabledBtn = () => {
    const checkedData = { ...data }
    delete checkedData.phone
    return Object.values(checkedData).some(item => !item) || status === 'submiting'
  }

  function validate (validationSubmit?: boolean) {
    if (!(validationRender || validationSubmit) || status === 'success') return

    let errorsTemp = {}
    if (data.name.length < 3) {
      errorsTemp = { ...errorsTemp, name: intl.formatMessage({ id: 'noticeErrorName' }) }
    }
    if (!data.email.match(emailRegexp)) {
      errorsTemp = { ...errorsTemp, email: intl.formatMessage({ id: 'noticeErrorEmail' }) }
    }
    if (data.message.length < 10) {
      errorsTemp = { ...errorsTemp, message: intl.formatMessage({ id: 'noticeErrorMessage' }) }
    }

    if (Object.keys(errorsTemp).length) {
      setErrors(errorsTemp)
      setStatus('error')
      return false
    } else {
      setErrors({})
      setStatus(null)
      return true
    }
  }

  function encode (data: { [key: string]: string }) {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&')
  }

  function analytics () {
    // @ts-ignore
    window.ym(47951645, 'reachGoal', 'Lead', data)
    // @ts-ignore
    window.gtag('event', 'sendLead', { event_category: 'lead', event_action: 'send' })
    // @ts-ignore
    window.fbq('track', 'Lead')
  }

  function submitForm (ev: FormEvent) {
    ev.preventDefault()

    if (!validationRender) {
      setValidationReady(true)
    }

    if (validate(true)) {
      setStatus('submiting')

      analytics()

      fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({
          'form-name': 'contact',
          ...data
        })
      })
        .then(() => {
          setStatus('success')
          setData(initialValues)
          setValidationReady(false)
          setTimeout(() => {
            setStatus(null)
          }, 2000)
        })
        .catch(error => {
          alert(error)
          setStatus(null)
        })
    }
  }

  useEffect(() => {
    if (validationRender) {
      validate()
    }
  }, [validationRender, data])

  return (
    <>
      <Title id="feedback" medium>
        <FormattedMessage id="feedbackTitle" />
      </Title>
      <Push size={16} />
      <Description>
        <FormattedMessage id="feedbackDescription" />
      </Description>

      <StyledForm
        name="contact"
        method="post"
        action="/success/"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={submitForm}
      >
        <input type="hidden" name="bot-field" />
        <input type="hidden" name="form-name" value="contact" />
        <Push size={12} adaptiveSize={32} />
        <div className="form-row">
          <div className="form-col-3">
            <FormControl
              name="name"
              danger={Object.prototype.hasOwnProperty.call(errors, 'name')}
              placeholder={intl.formatMessage({ id: 'feedbackName' })}
              value={data.name}
              onChange={ev => {
                setData({ ...data, name: ev.currentTarget.value })
              }}
            />
          </div>
          <div className="form-col-3">
            <FormControl
              name="email"
              danger={Object.prototype.hasOwnProperty.call(errors, 'email')}
              placeholder={intl.formatMessage({ id: 'feedbackEmail' })}
              value={data.email}
              onChange={ev => {
                setData({ ...data, email: ev.currentTarget.value })
              }}
            />
          </div>
          <div className="form-col-3">
            <FormControl
              name="phone"
              placeholder={intl.formatMessage({ id: 'feedbackPhone' })}
              value={data.phone}
              onChange={ev => {
                setData({ ...data, phone: ev.currentTarget.value })
              }}
            />
          </div>
        </div>
        <Push size={24} />
        <FormControl
          name="message"
          danger={Object.prototype.hasOwnProperty.call(errors, 'message')}
          tag="textarea"
          placeholder={intl.formatMessage({ id: 'feedbackText' })}
          value={data.message}
          onChange={ev => {
            setData({ ...data, message: ev.currentTarget.value })
          }}
        />
        <Push size={24} adaptiveSize={8} />
        <div className="form-row">
          <div className="form-col-2">
            <span className="form-text">
              <FormattedMessage id="feedbackTip" />
              <a href={locales[locale].default ? '/privacy/' : `/${locale}/privacy/`} target="_blank" rel="noreferrer">
                <FormattedMessage id="feedbackTipLink" />
              </a>
            </span>
          </div>
          <div className="form-col-2">
            <Button type="submit" center disabled={disabledBtn()}>
              <FormattedMessage id="feedbackTipBtn" />
            </Button>
          </div>
        </div>
      </StyledForm>

      {status && status === 'success' ? (
        <Notification>
          <FormattedMessage id="noticeSuccess" />
        </Notification>
      ) : status === 'error' ? (
        <Notification error>{Object.values(errors).join(', ')}</Notification>
      ) : null}
    </>
  )
}

const StyledForm = styled.form`
  max-width: 576px;
  margin-right: auto;
  margin-left: auto;

  .form-row {
    display: flex;
    flex-wrap: wrap;
    margin-top: -24px;
    margin-left: -16px;
  }

  .form-col-3 {
    width: calc(100% / 3);
    padding-top: 24px;
    padding-left: 16px;

    @media ${breakPoint.phone} {
      width: 100%;
    }
  }

  .form-col-2 {
    width: 50%;
    padding-top: 24px;
    padding-left: 16px;

    @media ${breakPoint.phone} {
      width: 100%;
    }
  }

  .form-text {
    font-size: ${styleVars.fontSizeXsmall};

    a {
      color: inherit;
    }
  }
`

export default injectIntl(FeedBack)
