import React from 'react'
import styled, { css } from 'styled-components'
import { breakPoint, styleVars } from '../../theme/theme'

type Props = {
  children: string
  as?: string
  super?: boolean
  primary?: boolean
  medium?: boolean
  id?: string
}

const Title = ({ children, ...props }: Props) => <StyledTitle {...props}>{children}</StyledTitle>

const StyledTitle = styled.div`
  margin: 0;
  font-size: ${props => (props.super ? '48px' : props.primary ? '24px' : props.medium ? '36px' : '40px')};
  font-weight: ${styleVars.fontWeightBold};
  text-align: center;
  color: ${({ primary }) => primary && styleVars.brandPrimary};

  ${props =>
    !(props.primary || props.medium) &&
    css`
      @media ${breakPoint.phone} {
        font-size: 32px;
      }
    `};

  ${({ id }) =>
    id &&
    css`
      margin-top: -96px;
      padding-top: 96px;
    `}
`

export default Title
