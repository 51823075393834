import React, { ReactNode } from 'react'
import styled from 'styled-components'

import { resetUl } from '../../theme/mixins'
import { breakPoint, styleVars } from '../../theme/theme'

type Props = {
  children: ReactNode
}

const BrandUl = ({ children }: Props) => <StyledUl>{children}</StyledUl>

const StyledUl = styled.ul`
  ${resetUl};
  max-width: 690px;
  margin-right: auto;
  margin-left: auto;
  padding-left: 20px;
  font-size: 18px;

  @media ${breakPoint.desktop} {
    padding-left: 0;
  }

  li {
    position: relative;
    padding-left: 38px;
    line-height: 1.3;

    &:not(:first-child) {
      margin-top: 32px;

      @media ${breakPoint.phone} {
        margin-top: 24px;
      }
    }

    &:before {
      content: '';
      position: absolute;
      top: 2px;
      left: 0;
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 8px 13px;
      border-color: transparent;
      border-left-color: ${({ theme }) => theme.colorReverce};
    }
  }

  .text-primary {
    font-weight: ${styleVars.fontWeightBold};
    color: ${styleVars.brandPrimary};
  }
`

export default BrandUl
