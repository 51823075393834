import imgSTARified1 from '../../images/screens/STARified/en-1.png'
import imgSTARified2 from '../../images/screens/STARified/en-2.png'
import imgSTARified3 from '../../images/screens/STARified/en-3.png'
import imgSTARified4 from '../../images/screens/STARified/en-4.png'

import imgDynamics1 from '../../images/screens/Dynamics/ru-1.png'
import imgDynamics2 from '../../images/screens/Dynamics/ru-2.png'
import imgDynamics3 from '../../images/screens/Dynamics/ru-3.png'
import imgDynamics4 from '../../images/screens/Dynamics/ru-4.png'

import imgTheWords1 from '../../images/screens/TheWords/1.png'
import imgTheWords2 from '../../images/screens/TheWords/2.png'
import imgTheWords3 from '../../images/screens/TheWords/3.png'
import imgTheWords4 from '../../images/screens/TheWords/4.png'

import imgCarNetwork1 from '../../images/screens/CarNetwork/ru-1.png'
import imgCarNetwork2 from '../../images/screens/CarNetwork/ru-2.png'
import imgCarNetwork3 from '../../images/screens/CarNetwork/ru-3.png'

import imgPlayground1 from '../../images/screens/Playground/ru-sm-1.png'
import imgPlayground2 from '../../images/screens/Playground/ru-sm-2.png'
import imgPlayground3 from '../../images/screens/Playground/ru-sm-3.png'
import imgPlayground4 from '../../images/screens/Playground/ru-sm-4.png'

import imgTeam1 from '../../images/screens/Team/ru-1.png'
import imgTeam2 from '../../images/screens/Team/ru-2.png'
import imgTeam3 from '../../images/screens/Team/ru-3.png'

export const projectsRu = [
  {
    name: 'The Words',
    description:
      'Приложение, в котором можно делиться историями анонимно. Надежно защищает конфиденциальность пользователей и создает безопасное пространство для общения. Стэк для быстрой разработки MVP: React Native, Hasura, GraphQL и Apollo.',
    images: [imgTheWords1, imgTheWords2, imgTheWords3, imgTheWords4],
    adaptiveImages: true,
    appStoreLink: 'https://apps.apple.com/us/app/the-words-anonymous-stories/id1502152404?l=ru&ls=1'
  },
  {
    name: 'Динамика',
    description:
      'Аналитическая система ценообразования на рынке недвижимости. Помогает застройщикам больше зарабатывать и быстрее продавать.',
    images: [imgDynamics3, imgDynamics2, imgDynamics1, imgDynamics4],
    webSiteLink: 'https://dinamika-app.ru/'
  },
  {
    name: 'STARified',
    description:
      'Фоторедактор с искусственным интеллектом. ИИ распознает людей и животных, позволяет менять фон, положение объектов, добавлять эффекты и анимации. Гибридное приложение с интерфейсом на React Native и работой с графикой через нативные API',
    images: [imgSTARified1, imgSTARified2, imgSTARified3, imgSTARified4],
    adaptiveImages: true
  },
  {
    name: 'Автосеть',
    description:
      'IT платформа автоматизации для дилера подержанных автомобилей. Создана как основа для масштабирования бизнеса и развития сети франчайзи. Автоматизирует рабочие процессы: собирает базу данных автомобилей, позволяет вести учет всех работ по предпродажной подготовке и отслеживать полный цикл сделок.',
    images: [imgCarNetwork1, imgCarNetwork2, imgCarNetwork3],
    webSiteLink: 'https://xn--80aej9aped4f.xn--p1ai/'
  },
  {
    name: 'Площадка',
    description:
      'Мобильный сервис по поиску и бронированию спортивных площадок в городе.  С его помощью пользователи могут искать подходящие площадки, бронировать их и оплачивать, организовывать встречи и оставлять отзывы.',
    images: [imgPlayground1, imgPlayground2, imgPlayground3, imgPlayground4],
    adaptiveImages: true,
    googlePlayLink: 'https://play.google.com/store/apps/details?id=ru.sportsground.ploschadka&hl=ru',
    appStoreLink: 'https://apps.apple.com/ru/app/%D0%BF%D0%BB%D0%BE%D1%89%D0%B0%D0%B4%D0%BA%D0%B0/id1299462488',
    webSiteLink: 'https://sportsground.ru/'
  },
  {
    name: 'Команда',
    description:
      'Корпоративная система для управления кадровыми и рекрутинговыми процессами. Дает возможность собирать базу данных кандидатов и сотрудников, искать по резюме, импортировать данные с hh.ru через браузерное расширение, учитывать и проводить оценочные мероприятия.',
    images: [imgTeam1, imgTeam2, imgTeam3]
  }
]
