import imgSTARified1 from '../../images/screens/STARified/en-1.png'
import imgSTARified2 from '../../images/screens/STARified/en-2.png'
import imgSTARified3 from '../../images/screens/STARified/en-3.png'
import imgSTARified4 from '../../images/screens/STARified/en-4.png'

import imgDynamics1 from '../../images/screens/Dynamics/en-1.png'
import imgDynamics2 from '../../images/screens/Dynamics/en-2.png'
import imgDynamics3 from '../../images/screens/Dynamics/en-3.png'
import imgDynamics4 from '../../images/screens/Dynamics/en-4.png'

import imgTheWords1 from '../../images/screens/TheWords/1.png'
import imgTheWords2 from '../../images/screens/TheWords/2.png'
import imgTheWords3 from '../../images/screens/TheWords/3.png'
import imgTheWords4 from '../../images/screens/TheWords/4.png'

import imgCarNetwork1 from '../../images/screens/CarNetwork/en-1.png'
import imgCarNetwork2 from '../../images/screens/CarNetwork/en-2.png'
import imgCarNetwork3 from '../../images/screens/CarNetwork/en-3.png'

import imgPlayground1 from '../../images/screens/Playground/en-sm-1.png'
import imgPlayground2 from '../../images/screens/Playground/en-sm-2.png'
import imgPlayground3 from '../../images/screens/Playground/en-sm-3.png'
import imgPlayground4 from '../../images/screens/Playground/en-sm-4.png'

import imgTeam1 from '../../images/screens/Team/en-1.png'
import imgTeam2 from '../../images/screens/Team/en-2.png'
import imgTeam3 from '../../images/screens/Team/en-3.png'

export const projectsEn = [
  {
    name: 'The Words',
    description:
      'The Words is an app where you can share stories anonymously whilst strongly protecting user privacy, creating a safe space for communication. We fully implemented MVP for iOS and Android in no time tanks to React Native, Hasura, GraphQL and Apollo.',
    images: [imgTheWords1, imgTheWords2, imgTheWords3, imgTheWords4],
    adaptiveImages: true,
    appStoreLink: 'https://apps.apple.com/us/app/the-words-anonymous-stories/id1502152404?l=ru&ls=1'
  },
  {
    name: 'Dynamics',
    description: 'Dynamics is an analytical system used by businesses to track prices in the real estate market.',
    images: [imgDynamics3, imgDynamics2, imgDynamics1, imgDynamics4],
    webSiteLink: 'https://dinamika-app.ru/'
  },
  {
    name: 'STARified',
    description:
      'STARified is an artifical intelligence photo editor app. It recognises images of people and animals and allows you to change the background, position of objects, add effects and animation. The application is hybrid: logic and interface in React native, AI and graphic effects in native code - everything is seamlessly integrated.',
    images: [imgSTARified1, imgSTARified2, imgSTARified3, imgSTARified4],
    adaptiveImages: true
  },
  {
    name: 'CRM for car dealers',
    description:
      'An IT solution for car dealers, created as a basis for scaling a business and developing a franchisee network. The platform automates the whole process. It collects a database of cars, exports data to websites, allows you to keep records of all pre-sale preparations and track the full cycle of transactions.',
    images: [imgCarNetwork1, imgCarNetwork2, imgCarNetwork3],
    webSiteLink: 'https://xn--80aej9aped4f.xn--p1ai/'
  },
  {
    name: 'Sportsground',
    description:
      'Sportsground is a mobile service for the search and booking of sports grounds in the city. With its help, users can search for suitable sites, book and pay for them, organise meetings and leave reviews. And administrators - to manage the schedule of objects and work with the customer database.',
    images: [imgPlayground1, imgPlayground2, imgPlayground3, imgPlayground4],
    adaptiveImages: true,
    googlePlayLink: 'https://play.google.com/store/apps/details?id=ru.sportsground.ploschadka&hl=ru',
    appStoreLink: 'https://apps.apple.com/ru/app/%D0%BF%D0%BB%D0%BE%D1%89%D0%B0%D0%B4%D0%BA%D0%B0/id1299462488',
    webSiteLink: 'https://sportsground.ru/'
  },
  {
    name: 'HRM for city administration',
    description:
      'We created a corporate system for managing personnel and recruiting processes. It makes it possible to collect a database of candidates and employees, search by resume, import data from job boards through a browser extension, take into account and conduct assessment activities.',
    images: [imgTeam1, imgTeam2, imgTeam3]
  }
]
