import React from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'

import AppStoreDark from '../../images/app-store-dark.svg'
import AppStoreLight from '../../images/app-store-light.svg'
import GooglePlayDark from '../../images/google-play-dark.svg'
import GooglePlayLight from '../../images/google-play-light.svg'
import WebSiteDark from '../../images/website-dark.svg'
import WebSiteLight from '../../images/website-light.svg'
import { styleVars } from '../../theme/theme'

type Props = {
  appStoreLink?: string
  googlePlayLink?: string
  webSiteLink?: string
}

const ProjectLinks = ({ appStoreLink, googlePlayLink, webSiteLink }: Props) => {
  return (
    <StyledProjectLinks>
      {appStoreLink && <a href={appStoreLink} className="project-link app-store-link" />}
      {googlePlayLink && <a href={googlePlayLink} className="project-link google-play-link" />}
      {webSiteLink && (
        <a href={webSiteLink} className="project-link website-link">
          <span>
            <FormattedMessage id="btnWebSite" />
          </span>
        </a>
      )}
    </StyledProjectLinks>
  )
}

const StyledProjectLinks = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: -24px;
  margin-left: -24px;

  .project-link {
    display: block;
    width: 132px;
    height: 40px;
    margin-top: 24px;
    margin-left: 24px;
    text-decoration: none;
    font-size: ${styleVars.fontSizeSmall};
    font-weight: ${styleVars.fontWeightBold};
    color: ${({ theme }) => (theme.dark ? '#000' : '#ececec')};
    background-size: cover;
    background-position: center;
  }

  .app-store-link {
    background-image: ${({ theme }) => (theme.dark ? `url(${AppStoreLight})` : `url(${AppStoreDark})`)};
  }

  .google-play-link {
    background-image: ${({ theme }) => (theme.dark ? `url(${GooglePlayLight})` : `url(${GooglePlayDark})`)};
  }

  .website-link {
    padding-top: 12px;
    padding-left: 24px;
    background-image: ${({ theme }) => (theme.dark ? `url(${WebSiteLight})` : `url(${WebSiteDark})`)};

    span {
      display: block;
      text-align: center;
    }
  }
`

export default ProjectLinks
